import store from '@/store'
import purchaseModel from '@/views/models/purchases/purchaseModel'

export const usePurchaseCart = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch(`${purchaseModel.name}/update`, { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const updateProductQty = (productId, productQty) => {
    return store.dispatch(`${purchaseModel.name}/updateAttribute`, {
      itemId: 'cart',
      attribute: 'items',
      value: [{
        id: productId,
        qty: productQty,
      }],
    })
  }

  // eslint-disable-next-line arrow-body-style
  const applyCoupon = couponCode => {
    console.log('DEBUG', {
      itemId: 'cart',
      attribute: 'coupon_code',
      value: couponCode,
    })
    return store.dispatch(`${purchaseModel.name}/updateAttribute`, {
      itemId: 'cart',
      attribute: 'coupon_code',
      value: couponCode,
    })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => updateProductQty(productId, 0)

  // eslint-disable-next-line arrow-body-style
  const checkoutPurchase = (paymentMethodCode, paymentData) => {
    return store.dispatch(`${purchaseModel.name}/create`, {
      payment_method_code: paymentMethodCode,
      payment_data: paymentData,
    })
  }

  return {
    checkoutPurchase,
    addProductInCart,
    updateProductQty,
    removeProductFromCart,
    applyCoupon,
  }
}
